<template>
  <div class="table-empty">
    <iq-title class-name="mobileHeader" class="table-empty__title">
      <Icon name="IconAttentionCircle" width="25px" height="25" />
      <span>Внимание!</span>
    </iq-title>

    <iq-title class-name="text">{{ warning }}</iq-title>

    <div v-if="!isViewer && !isSupport" class="table-empty__block">
      <iq-button icon="DriverPlus" color="main-dark" @onClick="$emit('add')">
        {{ proposition }}
      </iq-button>
    </div>
  </div>
</template>

<script>
import Icon from '@/UI/icon/Icon'
import IqButton from '@/views/ui/components/buttons/IqButton'
import IqTitle from '@/views/ui/components/typography/IqTitle'
export default {
  name: 'TableEmpty',
  components: { IqButton, IqTitle, Icon },
  props: {
    warning: {
      type: String,
      default: '',
    },
    proposition: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass">
.table-empty
  background: $color-white
  border: $border-default
  box-sizing: border-box
  box-shadow: $box-shadow-default
  border-radius: 4px
  padding: 20px
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  height: clamp(400px, calc(100vh - 300px), calc(100vh - 300px))
  &__title.mobileHeader
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: 0
  & > * + *
    margin-top: 20px
  .text
    color: #606266
    margin-top: 20px

@media (max-width: 1199px)
  .table-empty
    background: transparent
    border: transparent
    box-shadow: none
    height: 30vh
    &__title.mobileHeader
      column-gap: 5px
      font-weight: 500
      font-size: 22px
      line-height: 22px
      span
        padding-top: 2px
    .text
      margin-top: 16px
      margin-bottom: 0
</style>
